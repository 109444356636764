import React, { useRef, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useTransition, useChain, animated, config } from "react-spring"
import NavLink from "./NavLink"
import StyledSidebar from "./StyledSidebar"
import Close from "../../images/Close"
import BrandLogo from "../../images/logo"

const Sidebar = React.forwardRef(
  ({ show, toggleSidebar, scrollToComponent }, ref) => {
    const sidebarRef = useRef()
    const transition = useTransition(show, null, {
      from: {
        transform: "translateX(100%)",
      },
      enter: {
        transform: "translateX(0)",
      },
      leave: {
        transform: "translateY(100%)",
      },
      unique: true,
      ref: sidebarRef,
    })

    const items = ["About", "Approach", "Services", "FAQ", "Contact"]

    const links = ["about", "approach", "services", "faq", "contact"] //titles.map(title => title.uid);

    const itemsRef = useRef()
    const trail = useTransition(show ? items : [], item => item, {
      from: {
        opacity: 0,
        transform: "translateY(40px)",
      },
      enter: {
        opacity: 1,
        transform: "translateY(0)",
      },
      leave: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      ref: itemsRef,
      trail: 50,
      unique: true,
    })

    useChain(
      show ? [sidebarRef, itemsRef] : [itemsRef, sidebarRef],
      show ? [0, 0.25] : [0, 0.6]
    )

    return transition.map(({ item, key, props }) =>
      item ? (
        <StyledSidebar key={key} style={props} ref={ref}>
          <BrandLogo className="brand-logo" />
          {trail.map(({ item, key, props }, i) => (
            <animated.div key={item} style={props} className="sidebar__item">
              <NavLink
                link={links[i]}
                title={item}
                scrollToComponent={scrollToComponent}
              />
            </animated.div>
          ))}
          <Close className="close" onClick={toggleSidebar} />
        </StyledSidebar>
      ) : null
    )
  }
)

export default Sidebar
