import React from "react"
import { Link } from "gatsby"
const NavLink = ({ link, title, scrollToComponent }) => {
  return (
    <div data-id={link} onClick={scrollToComponent}>
      <h2>{title}</h2>
    </div>
  )
}

export default NavLink
