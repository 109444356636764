import React, { Fragment } from "react"
import styled, { css } from "styled-components"

const lineTransitionTime = "0.3s"

const StyledBurgerMenu = styled.div`
  position: fixed;
  width: 3rem;
  height: 1rem;
  cursor: pointer;
  outline: none;
  z-index: 21;
  -webkit-tap-highlight-color: transparent;
  display: none;
  right: 10%;
  & .burger-menu-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    gap: 0.3rem;
    transition-duration: ${lineTransitionTime};
    color: transparent;
    & .line {
      position: relative;
      width: 3rem;
      height: 0.12rem;

      &:before,
      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: ${({ sidebarIsOpen }) =>
          sidebarIsOpen ? "black" : "white"};

        transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &:before {
        transform: scaleX(0);
        transform-origin: left;
      }

      &:after {
        transform-origin: right;
        transition-delay: 0.25s;
      }

      &:nth-child(1) {
        &:before {
          transition-delay: ${`${0.1 * 0}s`};
        }

        &:after {
          transition-delay: ${`${0.1 + 0.25 * 0}s`};
        }
      }
      &:nth-child(2) {
        &:before {
          transition-delay: ${`${0.1 * 1}s`};
        }

        &:after {
          transition-delay: ${`${0.1 + 0.25 * 1}s`};
        }
      }
      &:nth-child(3) {
        &:before {
          transition-delay: ${`${0.1 * 2}s`};
        }

        &:after {
          transition-delay: ${`${0.1 + 0.25 * 2}s`};
        }
      }
    }

    &:hover {
      & .line:before {
        transform: scaleX(1);
        transition-delay: 0.25s;
      }

      & .line:after {
        transform: scaleX(0);
        transition-delay: 0s;
      }
      & .line {
        &:nth-child(1) {
          &:before {
            transition-delay: ${`${0.1 + 0.25 * 0}s`};
          }

          &:after {
            transition-delay: ${`${0.1 * 0}s`};
          }
        }
        &:nth-child(2) {
          &:before {
            transition-delay: ${`${0.1 + 0.25 * 1}s`};
          }

          &:after {
            transition-delay: ${`${0.1 * 1}s`};
          }
        }
        &:nth-child(3) {
          &:before {
            transition-delay: ${`${0.1 + 0.25 * 2}s`};
          }

          &:after {
            transition-delay: ${`${0.1 * 2}s`};
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    display: inline-block;
  }
`

const NavBurger = ({ sidebarIsOpen, toggleSidebar }) => {
  return (
    <StyledBurgerMenu
      className="burger-menu"
      sidebarIsOpen={sidebarIsOpen}
      onClick={toggleSidebar}
    >
      <div className="burger-menu-lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </StyledBurgerMenu>
  )
}

export default NavBurger
