import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={73} height={72} viewBox="0 0 73 72" {...props}>
      <title>{"Group"}</title>
      <g
        stroke="#FFF"
        strokeWidth={5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <path d="M20.59 51.91l31.82-31.82M20.59 20.09l31.82 31.82" />
      </g>
    </svg>
  )
}

export default SvgComponent
