import { css } from "styled-components"

// Global style variables

export const color = {
  // Palette
  orange: "#BB6124",
  bg: "#414141",
  beige: "#D0C7BD",
  // coral

  // Monochrome
  grey: "#CACAC6",
  darker: "#2a2a28",
  darkest: "#1d1d1b",
}

export const typography = {
  type: {
    primary: '"Commissioner", sans-serif',
    secondary: '"NitidaDisplay-Book", serif',
  },
  weight: {
    regular: "400",
    bold: "600",
  },
  size: {
    s1: "0.75rem",
    s2: "1rem",
    s3: "1.125rem",
    m1: "1.25rem",
    m2: "1.5rem",
    m3: "2.25rem",
    l1: "2.5rem",
    l2: "3rem",
    l3: "15.75rem",
  },
}

export const breakpoint = 600
export const pageMargin = "5.55555"

export const arrowMargins = css`
  margin-left: 15px;

  @media (min-width: 640px) {
    margin-left: ${pageMargin * 1}%;
  }
  @media (min-width: 768px) {
    margin-left: ${pageMargin * 2}%;
  }
  @media (min-width: 1024px) {
    margin-left: ${pageMargin * 1.5}%;
  }
  @media (min-width: 1440px) {
    margin-left: ${pageMargin * 1.8}%;
  }
`

export const pageMargins = css`
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 640px) {
    padding-left: ${pageMargin * 1}%;
    padding-right: ${pageMargin * 1}%;
  }
  @media (min-width: 768px) {
    padding-left: ${pageMargin * 2}%;
    padding-right: ${pageMargin * 2}%;
  }
  @media (min-width: 1024px) {
    padding-left: ${pageMargin * 1.5}%;
    padding-right: ${pageMargin * 1.5}%;
  }
  @media (min-width: 1440px) {
    padding-left: ${pageMargin * 1.8}%;
    padding-right: ${pageMargin * 1.8}%;
  }
`

export const Grid = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.25rem;
`

export const sectionCss = css`
  width: 100%;
  height: 100vh;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const flexCentre = css`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const remCalc = size => {
  let remSize
  if (Number.isInteger(size)) {
    remSize = `${size / 16}rem`
  }
  if (Array.isArray(size)) {
    remSize = ""
    size.map(px => (remSize += `${px / 16}rem`))
  }

  return remSize
}
