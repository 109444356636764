/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { pageMargins, color } from "../shared/styles"
import throttle from "lodash/throttle"
import Header from "./Header"
import Sidebar from "./Sidebar"

import "./layout.css"

const StyledLayout = styled.div`
  margin: 0 auto;

  & .section_services {
    & strong {
      margin-top: 2rem;
    }
  }

  & .section_contact {
    background: white;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    a {
      text-decoration: none;
      color: ${color.bg};
    }
  }
  @media only screen and (max-width: 1024px) {
    & .brand-logo {
      width: 147px;
    }
  }
`

const Layout = ({ children }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
  const sidebarRef = useRef(null)
  const lastScrolltop = useRef(0)
  const toggleSidebar = () => {
    setSidebarIsOpen(prevState => !prevState)
  }

  const scrollToComponent = e => {
    const elem = document.querySelector(
      `.section_${e.currentTarget.dataset.id}`
    )
    window.scrollTo({ top: elem.offsetTop, behavior: "smooth" })
    if (window.innerWidth < 1024) {
      toggleSidebar()
    }
  }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const scrollEvent = e => {
    const header = document.querySelector(".header")
    if (window.innerWidth < 1024) {
      if (window.scrollY < lastScrolltop.current || window.scrollY < 5) {
        header.classList.remove("is-hidden")
      } else if (window.scrollY > lastScrolltop.current) {
        header.classList.add("is-hidden")
      }
      lastScrolltop.current = window.scrollY
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", throttle(scrollEvent, 100))
  }, [])

  return (
    <>
      <StyledLayout>
        <Header
          toggleSidebar={toggleSidebar}
          sidebarIsOpen={sidebarIsOpen}
          scrollToComponent={scrollToComponent}
        />
        <Sidebar
          show={sidebarIsOpen}
          ref={sidebarRef}
          toggleSidebar={toggleSidebar}
          scrollToComponent={scrollToComponent}
        />
        <main>{children}</main>
      </StyledLayout>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
