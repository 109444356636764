import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { pageMargins, color, remCalc, typography } from "../../shared/styles"
import NavBurger from "./NavBurger"
import Logo from "../../images/logo"

const brandLogoWidth = "15.18rem"

const StyledHeader = styled.div`
  width: 100%;
  height: 6rem;
  background-color: ${color.bg};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  ${pageMargins};
  transition: transform 0.3s ease-in-out;
  & .brand-logo {
    width: ${brandLogoWidth};
    display: inline-block;
    margin-top: 0.5rem;
  }

  &.is-hidden {
    transform: translate(0, -6rem);
  }

  & .nav__links {
    display: flex;
    align-items: center;
    font-family: ${typography.type.secondary};
    font-size: 1.5rem;
    & * {
      cursor: pointer;
      margin: 0 0 0 2vw;
      color: white;
      /* margin: 0 ${remCalc(30)}; */
      text-decoration: none;
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        margin: 0 10px;
      }
      &:hover {
        text-decoration: underline;
      }
      @media only screen and (min-width: 1200px) {
        margin: 0 0 0 3vw;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    position: fixed;
    height: 4rem;
    & .nav__links {
      display: none;
    }
  }
`

const Header = ({ toggleSidebar, sidebarIsOpen, scrollToComponent }) => {
  const items = ["About", "Approach", "Services", "FAQ", "Contact"]

  const links = ["about", "approach", "services", "faq", "contact"]

  return (
    <StyledHeader className="header">
      <Link to="/">
        <Logo className="brand-logo" />
      </Link>
      <div className="nav__links">
        {items.map((item, i) => (
          <div data-id={links[i]} onClick={scrollToComponent}>
            {item}
          </div>
        ))}
      </div>
      <NavBurger toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
    </StyledHeader>
  )
}

export default Header
