import styled from "styled-components"
import { animated } from "react-spring"
import { color, typography } from "../../shared/styles.js"
const StyledSidebar = styled(animated.div)`
  height: 100vh;
  width: 30rem;
  z-index: 20;
  background-color: ${color.bg};
  padding-top: 7rem;
  position: absolute;
  right: 0;
  top: 0;
  position: fixed;
  & .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 40px;
    height: 40px;
  }
  & a {
    text-decoration: none;
    color: white;
  }
  & .contact-link {
    background: ${color.orange};
    height: 48px;
    width: 240px;
    line-height: 48px;
    margin: 0 auto;
  }

  & .sidebar__item {
    text-align: left;

    padding: 1rem 40px 0;
    cursor: pointer;

    font-weight: bold;
    letter-spacing: 1px;
    & h1,
    h2 {
      font-size: 26px;
      color: white;
      margin-bottom: 5vh;
      text-align: left;
      font-family: ${typography.type.secondary};
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .brand-logo {
    position: absolute;
    top: 27px;
    left: 40px;
  }
  h3 {
    font-size: ${typography.size.s3};
    line-height: 2.5rem;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    & .sidebar__item {
      & h2 {
        font-size: 1.5rem;
        margin-bottom: 5vh;
      }
    }
    & h3 {
      font-size: 1rem;
    }
  }
`

export default StyledSidebar
